import { useQuery } from '@tanstack/react-query';
import { IFilterOptions } from 'src/apis/types/dropdownAPI';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';

interface IGetDropDownAPI {
  path: string;
  MSWKey: string;
  key: string;
  searchText?: string;
  enabled?: boolean;
}

const dropdownFetch = (
  path: string,
  MSWKey: string,
  searchText?: string,
): Promise<IFilterOptions> =>
  getFetch({
    path: `${path}${concatQuerys([{ param: 'searchText', value: searchText }], true)}`,
    key: MSWKey,
  });

export const useGetDropdownWithSearchAPI = ({
  key,
  path,
  MSWKey,
  searchText,
  enabled,
}: IGetDropDownAPI) => {
  const { data, ...restProps } = useQuery(
    [`DropDown${key}`, { searchText }],
    () => dropdownFetch(path, MSWKey, searchText),
    {
      enabled,
      staleTime: 5000,
    },
  );
  return {
    dropdownList:
      data?.entities?.map(({ properties: { displayText = '', value = '' } = {} }) => ({
        label: displayText,
        value,
      })) || [],
    dropdownLink: data?.links?.[0]?.href,
    ...restProps,
  };
};
