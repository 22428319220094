import { ReactNode, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { daDK, enUS, fiFI, frFR, ruRU, svSE, nbNO, nlNL, deDE } from '@mui/material/locale';
import {
  daDK as dataGridDaDK,
  enUS as dataGridEnUS,
  fiFI as dataGridFiFI,
  frFR as dataGridFrFR,
  ruRU as dataGridRuRU,
  svSE as dataGridSvSE,
  nbNO as dataGridNbNO,
  nlNL as dataGridNlNL,
  deDE as dataGridDeDE,
} from '@mui/x-data-grid/locales';
import {
  enUS as pickersEnUS,
  fiFI as pickersFiFI,
  frFR as pickersFrFR,
  ruRU as pickersRuRU,
  svSE as pickersSvSE,
  nbNO as pickersNbNO,
  nlNL as pickersNlNL,
  deDE as pickersDeDE,
} from '@mui/x-date-pickers/locales';
// This is for some reason not exported from the locale root
import { daDK as pickersDaDK } from '@mui/x-date-pickers/locales/daDK';
// This is needed to add default props for the DataGrid in theme
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { timeLogThemeOptions } from 'src/assets/themes/timelog.theme';

const localeMap = {
  da: [daDK, dataGridDaDK, pickersDaDK],
  de: [deDE, dataGridDeDE, pickersDeDE],
  en: [enUS, dataGridEnUS, pickersEnUS],
  fi: [fiFI, dataGridFiFI, pickersFiFI],
  nb: [nbNO, dataGridNbNO, pickersNbNO],
  nl: [nlNL, dataGridNlNL, pickersNlNL],
  ru: [ruRU, dataGridRuRU, pickersRuRU],
  sv: [svSE, dataGridSvSE, pickersSvSE],
  fr: [frFR, dataGridFrFR, pickersFrFR],
};

const useGetDateLocale = () => {
  const { currentLanguage, isSuccess } = useGetCurrentLanguage();

  if (isSuccess) {
    return currentLanguage ? localeMap[currentLanguage] : localeMap.en;
  }

  return localeMap.en;
};

export const MuiWrapper = ({ children }: { children: ReactNode }) => {
  const locale = useGetDateLocale();

  const timelogTheme = useMemo(() => createTheme(timeLogThemeOptions, ...locale), [locale]);
  return <ThemeProvider theme={timelogTheme}>{children}</ThemeProvider>;
};
