import {
  GridPaginationModel,
  GridRowParams,
  GridGroupingColDefOverride,
  UncapitalizedGridProSlotsComponent,
} from '@mui/x-data-grid-pro';
import { SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { Box, CircularProgress, DataGrid } from 'src/components/mui-components';
import ReportingWidgetCard from 'src/screens/Home/components/ReportingWidgetCard/ReportingWidgetCard';
import { WidgetEmpty, WidgetError } from 'src/screens/Home/components/ReportingWidgetState';
import scssTypography from 'src/assets/styles/variables/typography.module.scss';

interface IReportingWidgetDataGrid {
  customSx?: SxProps;
  data: {}[];
  columnData: {}[];
  slots?: Partial<UncapitalizedGridProSlotsComponent>;
  hasPagination: boolean;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel) => void;
  rowCount?: number;
  hasTreeData?: boolean;
  getTreeDataPath?: (row: any) => string[];
  groupingColDef?: GridGroupingColDefOverride<any>;
  getDetailPanelContent?: (params: GridRowParams) => React.ReactNode; // when using this props you must include prop for getDetailPanelHeight too.
  getDetailPanelHeight?: (params: GridRowParams<any>) => number | 'auto';
  disableColumnTopBorder?: boolean;
  disableCellBorder?: boolean;
  isLoading: boolean;
}

interface IReportingWidgetWithTable {
  customSx?: SxProps;
  name: string;
  title: string;
  data: {}[];
  columnData: {}[];
  slots?: Partial<UncapitalizedGridProSlotsComponent>;
  infoIconText: string;
  hasPagination?: boolean;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel) => void;
  rowCount?: number;
  hasTreeData?: boolean;
  getTreeDataPath?: (row: any) => string[];
  groupingColDef?: GridGroupingColDefOverride<any>;
  getDetailPanelContent?: (params: GridRowParams) => React.ReactNode;
  getDetailPanelHeight?: (params: GridRowParams<any>) => number | 'auto';
  disableColumnTopBorder?: boolean;
  disableCellBorder?: boolean;
  noDataMessage?: string;
  isEmpty: boolean;
  isLoading: boolean;
  isError: boolean;
}

const rowDataParser = (data: any) => {
  // DRO: id is MANDATORY for datagrid.
  const rowResult = data.map((item: any, index: any) => ({ id: index, ...item }));
  return rowResult;
};

const columnDataParser = (data: any) => {
  const colResult = data.map((item: any) => ({
    field: item.key,
    headerName: item.headerName,
    sortable: false,
    disableReorder: true,
    ...item,
  }));

  return colResult;
};

const ReportDataGrid = ({
  customSx = {},
  data,
  columnData,
  slots,
  hasPagination,
  isLoading,
  paginationModel,
  setPaginationModel,
  rowCount,
  hasTreeData,
  getTreeDataPath,
  groupingColDef,
  getDetailPanelContent,
  getDetailPanelHeight,
  disableColumnTopBorder = true,
  disableCellBorder = true,
}: IReportingWidgetDataGrid) => {
  const PAGE_SIZE_OPTIONS = [15, 25, 50, 100, 300];
  const [totalRecord, setTotalRecord] = useState(rowCount || 0);

  useEffect(() => {
    setTotalRecord((prevTotalRecord) => (rowCount !== undefined ? rowCount : prevTotalRecord));
  }, [rowCount, setTotalRecord]);

  const rows = rowDataParser(data);
  const cols = columnDataParser(columnData);

  return (
    <DataGrid
      sx={{ height: '470px', fontSize: scssTypography.fontSizeBody, ...customSx }}
      slots={slots || undefined}
      rows={rows}
      columns={cols}
      pagination={hasPagination}
      pageSizeOptions={hasPagination ? PAGE_SIZE_OPTIONS : undefined}
      paginationMode={hasPagination ? 'server' : undefined}
      paginationModel={hasPagination ? paginationModel : undefined}
      onPaginationModelChange={hasPagination ? setPaginationModel : undefined}
      rowCount={totalRecord}
      treeData={hasTreeData}
      getTreeDataPath={getTreeDataPath || undefined}
      groupingColDef={groupingColDef || undefined}
      getDetailPanelContent={getDetailPanelContent || undefined}
      getDetailPanelHeight={getDetailPanelHeight || undefined}
      loading={isLoading}
      hideFooter={!hasPagination}
      disableColumnTopBorder={disableColumnTopBorder}
      disableCellBorder={disableCellBorder}
      disableColumnMenu
      disableColumnResize
      disableRowSelectionOnClick
    />
  );
};

export const ReportingWidgetWithTable = ({
  customSx,
  name,
  title,
  data,
  slots,
  columnData,
  infoIconText,
  hasPagination = false,
  paginationModel,
  setPaginationModel,
  rowCount,
  hasTreeData,
  getTreeDataPath,
  groupingColDef,
  getDetailPanelContent,
  getDetailPanelHeight,
  disableColumnTopBorder,
  disableCellBorder,
  noDataMessage,
  isEmpty,
  isLoading,
  isError,
}: IReportingWidgetWithTable) => {
  const renderWidgetContentBasedOnState = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (isError) {
      return <WidgetError />;
    }
    if (isEmpty) {
      return <WidgetEmpty noDataMessage={noDataMessage} />;
    }

    return (
      <div data-automation-id={`ReportingWidgetDataGrid-${name}`}>
        <ReportDataGrid
          customSx={customSx}
          data={data}
          columnData={columnData}
          slots={slots}
          hasPagination={hasPagination}
          isLoading={isLoading}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          rowCount={rowCount}
          hasTreeData={hasTreeData}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          disableColumnTopBorder={disableColumnTopBorder}
          disableCellBorder={disableCellBorder}
        />
      </div>
    );
  };

  return (
    <ReportingWidgetCard title={title} name={name} infoIconText={infoIconText} hasAction>
      {renderWidgetContentBasedOnState()}
    </ReportingWidgetCard>
  );
};

export default ReportingWidgetWithTable;
