import { useQuery } from '@tanstack/react-query';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { IRevenueForecastResult } from '../../types';

const REVENUE_FORECAST_URL_PATH = '/api/v2/revenue-forecast';
export const REVENUE_FORECAST_MSW_STATUS_KEY = 'GetRevenueForecastAPI';
export const REVENUE_FORECAST_KEY = 'REVENUE_FORECAST';

const revenueForecastFetch = (
  filters: string,
  params: { [key: string]: string },
): Promise<IRevenueForecastResult> =>
  getFetch({
    path: `${REVENUE_FORECAST_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value:
          // we need the hyphens in dates
          key === 'period-starts-at' || key === 'period-ends-at'
            ? params[key]
            : params[key].replaceAll('-', ''),
      })),
    )}`.concat(filters),
    key: REVENUE_FORECAST_MSW_STATUS_KEY,
  });

export const useGetRevenueForecast = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: { [key: string]: string },
) => {
  const items = Object.keys(selectedFilterList);
  const filtersParam = `${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `${item}Ids`,
        value: selectedFilterList[item].values.map((value) => value.value).toString(),
        isAppended: true,
      })),
  )}${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `Is${item}Inclusive`,
        value: String(selectedFilterList[item].isInclude),
        isAppended: true,
      })),
  )}`;

  const {
    data: {
      model: {
        properties: {
          periods = [],
          children = [],
          summations = [],
          viewOptions = {},
          responseType = '',
        } = {},
      } = {},
    } = {},
    isError,
    ...restProps
  } = useQuery(
    [REVENUE_FORECAST_KEY, params || {}, selectedFilterList || {}],
    async () => {
      const response = await revenueForecastFetch(filtersParam, params || {});

      return response;
    },
    {
      // any previous data will be kept when fetching new data because the query key changed.
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );

  return {
    periods,
    children,
    summations,
    isEmpty: children.length <= 0,
    isFilterHasValue: Object.keys(selectedFilterList).length !== 0,
    isError,
    responseType,
    viewOptions,
    ...restProps,
  };
};
