export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: {
      LastSubmissionDaysCount: 451,
      CompanyAverageDaysCount: 1.0,
      SecondLastSubmissionDaysCount: 1,
    },
    Type: 'LastSubmission',
    Width: 0,
    Height: 0,
    SortOrder: 0,
    PaginationInfo: {},
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/last-submission-widget-data?siteid=%7b520abe0d-c7e2-4a73-a5f2-c349c48e4e25%7d',
      Rel: 'self',
    },
  ],
};
