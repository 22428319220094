import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'src/components/mui-components';
import { IconButton } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { AssignActionProps } from '../../types';

export const AssignAction = ({
  iconName,
  tooltipText,
  heading,
  closeText,
  isOpen,
  setIsOpen,
  onDismiss,
  children,
  footerComponent,
  onConfirm,
  initialFocusRef,
}: AssignActionProps) => {
  const { t } = useTranslation('resourcePlanner');
  const [isOpenLocal, setIsOpenLocal] = useState(false);
  const open = () => (setIsOpen ? setIsOpen(true) : setIsOpenLocal(true));
  const close = () => (setIsOpen ? setIsOpen(false) : setIsOpenLocal(false));
  const onConfirmWithClose = () => {
    if (onConfirm) onConfirm();
    close();
  };
  const handleEntering = () => {
    if (initialFocusRef?.current != null) {
      initialFocusRef?.current.focus();
    }
  };
  const localOnDismiss = onDismiss || close;

  return (
    <>
      <IconButton size="compact" iconName={iconName} onClick={open} tooltipText={tooltipText} />

      <Dialog
        open={isOpen || isOpenLocal}
        onClose={localOnDismiss}
        fullWidth
        maxWidth="xs"
        TransitionProps={{ onEntering: handleEntering }}
        disableRestoreFocus
      >
        <DialogTitle closeText={closeText} closeModal={localOnDismiss}>
          {heading}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>

        {footerComponent && <DialogActions>{footerComponent}</DialogActions>}

        {onConfirm && (
          <DialogActions>
            <Button variant="outlined" onClick={close} autoFocus>
              {t('Cancel')}
            </Button>
            <Button variant="contained" onClick={onConfirmWithClose}>
              {t('Ok').toUpperCase()}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
