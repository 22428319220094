import { Avatar, Box, Typography, Stack } from 'src/components/mui-components';
import HighlightMatchingText from 'src/components/utils/HighlightMatchingText';
import { getUserAvatar } from 'src/utils/getUserAvatar';

interface AssignItemProps {
  mainText: string;
  id?: string;
  secondaryText?: string;
  tertiaryText?: string;
  type?: string;
  searchText?: string;
}

export const AssignItem = ({
  id,
  mainText,
  secondaryText,
  tertiaryText,
  type,
  searchText,
}: AssignItemProps) => {
  const showImage = type === 'Employee' && id;

  return (
    <Stack direction="row" alignItems="center">
      {showImage && (
        <Box component="span">
          <Avatar src={getUserAvatar(id)} />
        </Box>
      )}
      <Stack gap={0}>
        <Typography component="span">
          <HighlightMatchingText name={mainText} matchName={searchText} />
        </Typography>
        {secondaryText && (
          <Typography component="span">
            <HighlightMatchingText name={secondaryText} matchName={searchText} />
          </Typography>
        )}
        {tertiaryText && (
          <Typography component="span">
            <HighlightMatchingText name={tertiaryText} matchName={searchText} />
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
