import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  PaperProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Paper, TextField } from 'src/components/mui-components';
import { DataAutomationId } from 'src/utils/ts-utils';

export interface AutocompleteProps<T>
  extends Omit<
      MuiAutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>,
      'renderInput'
    >,
    DataAutomationId {
  renderInput?: MuiAutocompleteProps<
    T,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >['renderInput'];
}

const ElevatedPaper = (props: PaperProps) => <Paper elevation={8} {...props} />;

export const Autocomplete = <T extends unknown>({ ...rest }: AutocompleteProps<T>) => {
  const { t } = useTranslation('filter');

  return (
    <MuiAutocomplete
      ChipProps={{ color: 'primary', size: 'small', ...rest.ChipProps }}
      PaperComponent={ElevatedPaper}
      openText={t('FilterAutocompleteOptionsListOpen')}
      closeText={t('FilterAutocompleteOptionsListClose')}
      clearText={t('FilterAutocompleteClearSelectedOptions')}
      loadingText={t('FilterAutocompleteOptionsLoading')}
      noOptionsText={t('FilterAutocompleteNoOptionsAvailable')}
      renderInput={(params) => (
        <TextField
          autoFocus
          ariaLabel={t('FilterAutocompleteSearchPlaceholder')}
          placeholder={t('FilterAutocompleteSearchPlaceholder')}
          data-automation-id={`${rest['data-automation-id']}TextField`}
          {...params}
        />
      )}
      {...rest}
      data-automation-id={rest['data-automation-id']}
    />
  );
};
