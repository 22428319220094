import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetFrontPageFeature, usePutFrontPageFeature } from 'src/apis/frontPageFeatureAPI';
import { useUpdateMilestoneDays } from 'src/apis/settingsAPI';
import { Button, TextField, ToastifyAlert } from 'src/components/mui-components';
import { useIsPsaProduct } from 'src/utils/siteInfo';
import { SettingsContentWrapper } from 'src/components/layout/SettingsContentWrapper';

interface SwitchSectionProps {
  title: string;
  items: {
    name: string;
    label: string;
    extra?: JSX.Element;
    tooltipTitle?: string;
    tooltipDesc?: string;
  }[];
  checkedItems: Record<string, boolean>;
  onToggle: (name: string, isChecked: boolean) => void;
}

const SwitchSection: React.FC<SwitchSectionProps> = ({ title, items, checkedItems, onToggle }) => {
  const [openItem, setOpenItem] = useState<string | null>(null);
  const { t: tSysAdminFrontPage } = useTranslation('systemAdminFrontPage');

  return (
    <FormControl component="fieldset" data-automation-id={`SwitchSection${title}`}>
      <FormLabel component="legend" focused={false}>
        <strong>{title}</strong>
      </FormLabel>
      <FormGroup>
        {items.map((item) => (
          <Box key={item.name}>
            <FormControlLabel
              key={item.name}
              control={
                <Switch
                  checked={!!checkedItems[item.name]}
                  onChange={(event) => onToggle(item.name, event.target.checked)}
                  data-automation-id={`Switch${item.name}`}
                />
              }
              label={
                <>
                  {item.label}
                  {item.extra ?? null}
                  {item.tooltipTitle && (
                    <Box sx={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                      <IconButton
                        title={tSysAdminFrontPage('InfoIconTooltipTitle')}
                        data-automation-id={`${item.name}ModalTriggerButton`}
                        onClick={(event) => {
                          event.preventDefault();
                          setOpenItem(item.name);
                        }}
                      >
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </Box>
                  )}
                </>
              }
            />
            <Dialog open={openItem === item.name} onClose={() => setOpenItem(null)}>
              <DialogTitle id={`InfoDialog-Title-${title}`}>{item.tooltipTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText id={`InfoDialog-Description-${title}`}>
                  {item.tooltipDesc}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenItem(null)}
                  data-automation-id={`${title}InfoModalCloseButton`}
                >
                  <Typography>{tSysAdminFrontPage('ModalCloseButton')}</Typography>
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        ))}
      </FormGroup>
    </FormControl>
  );
};

interface IExtra {
  checkedFeatures: Record<string, any>;
  featureValues: Record<string, any>;
  milestoneDaysOnBlur: (value: string) => void;
  setFeatureValues: (value: SetStateAction<Record<string, any>>) => void;
  tSysAdminFrontPage: TFunction<'systemAdminFrontPage', undefined>;
}

const Extra = ({
  checkedFeatures,
  featureValues,
  milestoneDaysOnBlur,
  setFeatureValues,
  tSysAdminFrontPage,
}: IExtra) => (
  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
    {tSysAdminFrontPage('MilestonesMessage')}
    <TextField
      hiddenLabel
      ariaLabel="Milestone Days"
      data-automation-id="MilestonesDaysInput"
      id="Milestones"
      value={featureValues?.ShowMileStones !== undefined ? featureValues.ShowMileStones : ''}
      variant="outlined"
      size="small"
      disabled={!checkedFeatures?.ShowMileStones}
      onChange={(e) => {
        if (!Number.isNaN(Number(e.target.value))) {
          setFeatureValues({ ...featureValues, ShowMileStones: e.target.value });
        } else {
          setFeatureValues({
            ...featureValues,
            ShowMileStones: featureValues?.ShowMileStones,
          });
        }
      }}
      onBlur={(e) => milestoneDaysOnBlur(e.target.value)}
      sx={{ width: 50, ml: 1, mr: 1 }}
    />
    {tSysAdminFrontPage('MilestonesDays')}
  </Box>
);

export const FrontPage = () => {
  const { data } = useGetFrontPageFeature();
  const { mutate: updateFeature } = usePutFrontPageFeature();
  const { mutate: updateMilestoneDays } = useUpdateMilestoneDays();
  const isPsaProduct = useIsPsaProduct();

  const { t: tSysAdminFrontPage } = useTranslation('systemAdminFrontPage');
  const { t: tWidget } = useTranslation('reportingWidgets');
  const { t: tFrontPage } = useTranslation('frontpage');
  const { t: tNotification } = useTranslation('notificationCenter');

  const [checkedFeatures, setCheckedFeatures] = useState<Record<string, any>>({});
  const [featureValues, setFeatureValues] = useState<Record<string, any>>({});

  useEffect(() => {
    if (data) {
      const newCheckedFeatures = data.reduce(
        (acc: Record<string, boolean>, feature: { name: string; enabled: boolean }) => {
          acc[feature.name] = feature.enabled;
          return acc;
        },
        {},
      );

      if (JSON.stringify(newCheckedFeatures) !== JSON.stringify(checkedFeatures)) {
        setCheckedFeatures(newCheckedFeatures);
      }

      const newFeatureValues = data.reduce(
        (acc: Record<string, any>, feature: { name: string; value?: number }) => {
          if (feature.value !== undefined) {
            acc[feature.name] = feature.value;
          }
          return acc;
        },
        {},
      );
      if (JSON.stringify(newFeatureValues) !== JSON.stringify(featureValues)) {
        setFeatureValues(newFeatureValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const toggleCheckbox = async (name: string, isChecked: boolean) => {
    const feature = data.find((feat: { name: string }) => feat.name === name);

    if (!feature) return;

    const response = await updateFeature(
      {
        featureId: feature.identifier,
        featureEnable: isChecked,
      },
      {
        onSuccess: () => {
          const status = isChecked
            ? tSysAdminFrontPage('FeatureEnabled')
            : tSysAdminFrontPage('FeatureDisabled');
          toast.success(
            <ToastifyAlert
              description={tSysAdminFrontPage('FeatureUpdateSuccessMessage', {
                STATUS: status,
              })}
            />,
            {
              autoClose: 2000,
              closeButton: false,
            },
          );
        },
      },
    );

    if (response !== undefined && response !== null) {
      setCheckedFeatures((prev: any) => ({ ...prev, [name]: isChecked }));
    }
  };

  const milestoneDaysOnBlur = (value: string) => {
    updateMilestoneDays(value, {
      onSuccess: () => {
        toast.success(
          <ToastifyAlert description={tSysAdminFrontPage('MilestonesUpdateSuccessMessage')} />,
          {
            autoClose: 2000,
            closeButton: false,
          },
        );
      },
    });
  };

  const sections = [
    {
      title: tSysAdminFrontPage('InfoName'),
      items: [
        {
          name: 'FrontPageInternalMessage',
          label: tFrontPage('InternalInformationHeading'),
          tooltipTitle: tFrontPage('InternalInformationHeading'),
          tooltipDesc: tFrontPage('InternalInformationText'),
        },
      ],
      isVisible: isPsaProduct,
    },
    {
      title: tSysAdminFrontPage('NotificationName'),
      items: [
        {
          name: 'DesignSevenNotificationCenter',
          label: tNotification('Heading'),
          tooltipTitle: tNotification('InfoModalHeading'),
          tooltipDesc: tNotification('InfoModalText'),
        },
      ],
      isVisible: isPsaProduct,
    },
    {
      title: tSysAdminFrontPage('MilestonesName'),
      items: [
        {
          name: 'ShowMileStones',
          label: tSysAdminFrontPage('MilestonesInInfoboxName'),
          tooltipTitle: tSysAdminFrontPage('MilestonesName'),
          tooltipDesc: tSysAdminFrontPage('MilestonesText'),
          extra: (
            <Extra
              checkedFeatures={checkedFeatures}
              featureValues={featureValues}
              milestoneDaysOnBlur={milestoneDaysOnBlur}
              setFeatureValues={setFeatureValues}
              tSysAdminFrontPage={tSysAdminFrontPage}
            />
          ),
        },
      ],
      isVisible: true,
    },
    {
      title: tSysAdminFrontPage('WidgetsName'),
      items: [
        {
          name: 'WidgetInvoicedPerMonth',
          label: tWidget('InvoicedPerMonth'),
          tooltipTitle: tWidget('InvoicedPerMonth'),
          tooltipDesc: tWidget('InvoicedPerMonthWidgetDescription'),
        },
        {
          name: 'WidgetAverageHourlyRate',
          label: tWidget('AverageHourlyRate'),
          tooltipTitle: tWidget('AverageHourlyRate'),
          tooltipDesc: tWidget('AverageHourlyRateWidgetDescription'),
        },
        {
          name: 'WidgetPlannedAbsence',
          label: tWidget('PlannedAbsence'),
          tooltipTitle: tWidget('PlannedAbsence'),
          tooltipDesc: tWidget('PlannedAbsenceWidgetDescription'),
        },
        {
          name: 'WidgetProjectEconomyHealth',
          label: tWidget('ProjectEconomyHealth'),
          tooltipTitle: tWidget('ProjectEconomyHealth'),
          tooltipDesc: tWidget('ProjectEconomyHealthWidgetDescription'),
        },
        {
          name: 'WidgetLastSubmission',
          label: tWidget('LastDaysSubmission'),
          tooltipTitle: tWidget('LastDaysSubmission'),
          tooltipDesc: tWidget('LastDaysSubmissionWidgetDescription'),
        },
        {
          name: 'WidgetVacationCalculation',
          label: tWidget('VacationCalculation'),
          tooltipTitle: tWidget('VacationCalculation'),
          tooltipDesc: tWidget('VacationCalculationWidgetDescription'),
        },
      ],
      isVisible: true,
    },
  ];

  return (
    <SettingsContentWrapper
      header={tSysAdminFrontPage('Heading')}
      description={tSysAdminFrontPage('Description')}
    >
      {sections.map((section) => {
        // Check if all feature names in the section are in checkedFeatures
        const allFeaturesExist = section.items.every((item) => item.name in checkedFeatures);
        return (
          allFeaturesExist &&
          section.isVisible && (
            <SwitchSection
              key={section.title}
              title={section.title}
              items={section.items}
              checkedItems={checkedFeatures}
              onToggle={toggleCheckbox}
            />
          )
        );
      })}
    </SettingsContentWrapper>
  );
};
