import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';

const PUT_FRONT_PAGE_FEATURE_URL_PATH = '/api/v2/general/features';
export const PUT_FRONT_PAGE_FEATURE_MSW_STATUS_KEY = 'putFrontPageFeatureAPI';
export const GET_FRONT_PAGE_FEATURE_KEY = 'GET_FRONT_PAGE_FEATURE';

export interface IUpdateFeature {
  featureId: number;
  featureEnable: boolean;
}

const updateFrontPageFeature = (updateModel: IUpdateFeature) =>
  putFetch({
    path: `${PUT_FRONT_PAGE_FEATURE_URL_PATH}${concatQuerys([
      { param: 'identifier', value: updateModel.featureId.toString() },
    ])}`,
    key: PUT_FRONT_PAGE_FEATURE_MSW_STATUS_KEY,
    body: JSON.stringify({
      enabled: updateModel.featureEnable,
    }),
  });

export const usePutFrontPageFeature = () => {
  const queryClient = useQueryClient();

  return useMutation(updateFrontPageFeature, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_FRONT_PAGE_FEATURE_KEY]);
    },
  });
};
