import { ReactNode } from 'react';
import { Box, Stack } from 'src/components/mui-components';

import grays from 'src/assets/styles/variables/colors/global/grays.module.scss';
import styles from './ScrollContainer.module.scss';

interface ScrollContainerProps {
  children: ReactNode;
}
export const ScrollContainer = ({ children }: ScrollContainerProps) => (
  <div className={styles.scrollContainer}>
    <Box sx={{ p: 2, border: `1px solid ${grays.colorGray20}`, borderRadius: '2px' }}>
      <Stack gap={2}>{children}</Stack>
    </Box>
  </div>
);
