import {
  Box,
  Chip,
  CircularProgress,
  Typography,
  Tooltip,
  Stack,
} from 'src/components/mui-components';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useGetLastSubmissionReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { useTranslation } from 'react-i18next';
import { WidgetError } from 'src/screens/Home/components/ReportingWidgetState';
import ReportingWidgetCard from '../ReportingWidgetCard';

interface IChipStatus {
  label: string;
  color: 'warning' | 'success' | 'default' | 'error' | 'primary' | 'secondary' | 'info' | undefined;
  icon: React.ReactElement;
}

export const LastSubmissionReportingWidget = () => {
  const { widgetName, widgetData, isLoading, isError } = useGetLastSubmissionReportingWidget();
  const { lastSubmissionDaysCount, secondLastSubmissionDaysCount, companyAverageDaysCount } =
    widgetData;

  const { t } = useTranslation('reportingWidgets');
  const roundedCompanyAverageCount = Math.round(companyAverageDaysCount);

  // is previous is higher than current status is good
  const hasExceededPreviousSubmissionCount =
    lastSubmissionDaysCount > secondLastSubmissionDaysCount;
  const arrowDirection = () =>
    hasExceededPreviousSubmissionCount ? (
      <Tooltip placement="right" title={t('LastDaysSubmissionWidgetArrowDownText')}>
        <ArrowDownwardIcon color="error" sx={{ width: '40px', height: '40px' }} />
      </Tooltip>
    ) : (
      <Tooltip placement="right" title={t('LastDaysSubmissionWidgetArrowUpText')}>
        <ArrowUpwardIcon color="success" sx={{ width: '40px', height: '40px' }} />
      </Tooltip>
    );

  const getChipStatus = (companyAverage: number, lastSubmission: number): IChipStatus => {
    const excellentRange = companyAverage + 4;
    const goodWorkRange = companyAverage + 8;
    if (lastSubmission <= excellentRange)
      return {
        label: t('ChipExcellentText'),
        color: 'success',
        icon: <CelebrationOutlinedIcon sx={{ width: '20px', height: '20px' }} />,
      };
    if (lastSubmission > excellentRange && lastSubmission <= goodWorkRange)
      return {
        label: t('ChipGoodWorkText'),
        color: 'warning',
        icon: <ThumbUpAltOutlinedIcon sx={{ width: '20px', height: '20px' }} />,
      };
    return {
      label: t('ChipKeepTryingText'),
      color: 'error',
      icon: <ErrorOutlineOutlinedIcon sx={{ width: '20px', height: '20px' }} />,
    };
  };

  const chipStatus = getChipStatus(roundedCompanyAverageCount, lastSubmissionDaysCount);

  const renderWidgetContentBasedOnState = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (isError) {
      return <WidgetError />;
    }

    return (
      <Stack justifyContent="center" alignItems="center">
        <Typography>{t('LastDaysSubmissionWidgetUpperText')}</Typography>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography fontSize="96px">{lastSubmissionDaysCount}</Typography>
          {secondLastSubmissionDaysCount === lastSubmissionDaysCount ? <div /> : arrowDirection()}
        </Stack>
        <Typography>{t('LastDaysSubmissionWidgetLowerText')}</Typography>
        <Typography>{`${t(
          'LastDaysSubmissionCompanyAverageText',
        )} ${roundedCompanyAverageCount}`}</Typography>
        <Chip label={chipStatus.label} color={chipStatus.color} icon={chipStatus.icon} />
      </Stack>
    );
  };

  return (
    <ReportingWidgetCard
      title={t('LastDaysSubmission')}
      name={widgetName}
      infoIconText={t('LastDaysSubmissionWidgetDescription')}
      hasAction
    >
      {renderWidgetContentBasedOnState()}
    </ReportingWidgetCard>
  );
};
