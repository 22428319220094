import {
  DayRangePicker,
  Flex,
  RadioButton,
  Stack,
  TDateRange,
  Text,
} from 'src/components/ui-components';
import { Button, DialogContent, DialogActions } from 'src/components/mui-components';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import fi from 'date-fns/locale/fi';
import nb from 'date-fns/locale/nb';
import nl from 'date-fns/locale/nl';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import fr from 'date-fns/locale/fr';
import React, { useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { usePostDistributeUnplannedHours } from 'src/apis/resourcePlannerAPI';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import {
  getDateWithZeroOffsetFromDateString,
  getLanguageFromSiteLocale,
  localeDateFormat,
} from 'src/utils/date';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import InfoPanel from '../InfoPanel';

const localeMap = {
  da,
  de,
  en,
  fi,
  nb,
  nl,
  ru,
  sv,
  fr,
};

const getLabelForDistributeStartAndEndDateRadioSelection = (
  t: TFunction<'resourcePlanner', undefined>,
  isLowestLevel: boolean,
) => {
  if (isLowestLevel) {
    return t('ModalDistributeUnplannedBetweenStartAndEndDateLowestRowRadioSelection');
  }

  return t('ModalDistributeUnplannedBetweenStartAndEndDateRadioSelection');
};

const getIsDisableButton = (
  rowType: string | undefined,
  hasChildWithEndDateOnTodayOrAfter: boolean,
  isDistributeBetweenStartAndEndDate: boolean,
) => {
  if (rowType === 'employee' && !hasChildWithEndDateOnTodayOrAfter) {
    return true;
  }

  return !hasChildWithEndDateOnTodayOrAfter && isDistributeBetweenStartAndEndDate;
};

export type TCheckedRadioButtonSelection = 'DistributeBetweenStartAndEndDate' | 'CustomDateRange';

const handleOnRadioSelectionChanged = (
  selectionType: TCheckedRadioButtonSelection,
  setSelectionType: React.Dispatch<React.SetStateAction<TCheckedRadioButtonSelection>>,
) => {
  setSelectionType(selectionType);
};

interface IDistributeUnplannedWorkModal {
  isResourceRow: boolean;
  resourceId?: string;
  workItemId?: string;
  isLowestLevel: boolean;
  startsAt?: string;
  endsAt?: string;
  negativeUnplannedHours: number;
  rowType?: string;
  hasChildWithEndDateOnTodayOrAfter?: boolean;
  handleClose?: () => void;
}

export const DistributeUnplannedWorkloadModalContent = ({
  isResourceRow,
  resourceId,
  workItemId,
  isLowestLevel,
  startsAt,
  endsAt,
  negativeUnplannedHours,
  rowType,
  hasChildWithEndDateOnTodayOrAfter,
  handleClose,
}: IDistributeUnplannedWorkModal) => {
  const { t: tResourcePlanner } = useTranslation('resourcePlanner');
  const { t: tDayPicker } = useTranslation('dayPicker');

  const [checkedRadioButton, setCheckedRadioButton] = useState<TCheckedRadioButtonSelection>(
    'DistributeBetweenStartAndEndDate',
  );

  const { currentLanguage, isSuccess } = useGetCurrentLanguage();
  const siteLocale = useGetLocale();
  const { mutate: postDistributeUnplannedHours } = usePostDistributeUnplannedHours();

  // Date picker options
  const dayPickerStartsAt = getDateWithZeroOffsetFromDateString(startsAt);
  const dayPickerEndsAt = getDateWithZeroOffsetFromDateString(endsAt);
  const dayPickerToday = getDateWithZeroOffsetFromDateString(new Date().toISOString());

  const [dateRange, setDateRange] = useState<TDateRange | undefined>({
    from: dayPickerStartsAt > dayPickerToday ? dayPickerStartsAt : dayPickerToday,
    to: dayPickerEndsAt < dayPickerToday ? dayPickerToday : dayPickerEndsAt,
  });

  const siteLanguage = getLanguageFromSiteLocale(siteLocale);

  return (
    <>
      <DialogContent>
        <Stack verticalMargin="large">
          {isResourceRow ? (
            <>
              <Text>{tResourcePlanner('ModalDistributeUnplannedResourceDescription')}</Text>
              {!hasChildWithEndDateOnTodayOrAfter ? (
                <InfoPanel
                  iconName="info"
                  description={tResourcePlanner(
                    'ModalDistributeUnplannedNoTaskOnOrAfterEndDateResourceRow',
                  )}
                />
              ) : null}
            </>
          ) : (
            <Stack verticalMargin="xSmall">
              <Text as="p">{`${tResourcePlanner(
                'ModalDistributeUnplannedWorkItemDescription',
              )}:`}</Text>
              <RadioButton
                label={getLabelForDistributeStartAndEndDateRadioSelection(
                  tResourcePlanner,
                  isLowestLevel,
                )}
                name="distribution-type-radio-group"
                value="DistributeBetweenStartAndEndDate"
                checked={checkedRadioButton === 'DistributeBetweenStartAndEndDate'}
                onChange={() =>
                  handleOnRadioSelectionChanged(
                    'DistributeBetweenStartAndEndDate',
                    setCheckedRadioButton,
                  )
                }
              />
              <Flex>
                <Flex.Item>
                  <RadioButton
                    label={tResourcePlanner('ModalDistributeUnplannedInTheDateSpanRadioSelection')}
                    name="distribution-type-radio-group"
                    value="distributeInDateSpan"
                    checked={checkedRadioButton === 'CustomDateRange'}
                    onChange={() =>
                      handleOnRadioSelectionChanged('CustomDateRange', setCheckedRadioButton)
                    }
                  />
                </Flex.Item>
                <Flex.Item>
                  <DayRangePicker
                    mode="range"
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    fromDate={
                      dayPickerStartsAt > dayPickerToday ? dayPickerStartsAt : dayPickerToday
                    }
                    weekStartsOn={1}
                    showWeekNumber
                    locale={
                      isSuccess && currentLanguage ? localeMap[currentLanguage] : localeMap.en
                    }
                    onApplyHandler={() => {
                      handleOnRadioSelectionChanged('CustomDateRange', setCheckedRadioButton);
                    }}
                    inputFromLabel={tDayPicker('DateInputFromLabel')}
                    inputToLabel={tDayPicker('DateInputToLabel')}
                    buttonApplyText={tResourcePlanner('Ok').toUpperCase()}
                    buttonCancelText={tDayPicker('CancelButtonText')}
                    dateFormatInvalidMessage={tDayPicker('NotValidDateFormatMessage')}
                    dateInvalidMessage={tDayPicker('NotValidDate')}
                    startDateIsOutOfRangeMessage={tDayPicker('StartDateIsBeforeAllowedDateMessage')}
                    endDateIsOutOfRangeMessage={tDayPicker('EndDateIsBeforeAllowedDateMessage')}
                    dateFormat={localeDateFormat(siteLanguage || 'da')}
                  />
                </Flex.Item>
              </Flex>
            </Stack>
          )}
          {rowType !== 'employee' &&
          checkedRadioButton === 'DistributeBetweenStartAndEndDate' &&
          !hasChildWithEndDateOnTodayOrAfter ? (
            <InfoPanel
              iconName="info"
              description={tResourcePlanner(
                'ModalDistributeUnplannedNoTaskOnOrAfterEndDateWorkItemRow',
              )}
            />
          ) : null}
          {negativeUnplannedHours < 0 ? (
            <InfoPanel
              iconName="info"
              description={tResourcePlanner('ModalDistributeUnplannedInfo')}
            />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose && handleClose()}>
          {tResourcePlanner('Cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={getIsDisableButton(
            rowType,
            hasChildWithEndDateOnTodayOrAfter ?? false,
            checkedRadioButton === 'DistributeBetweenStartAndEndDate',
          )}
          onClick={() => {
            postDistributeUnplannedHours({
              resourceId,
              workItemId,
              startsAt:
                checkedRadioButton === 'CustomDateRange'
                  ? dateRange?.from?.toDateString()
                  : undefined,
              endsAt:
                checkedRadioButton === 'CustomDateRange'
                  ? dateRange?.to?.toDateString()
                  : undefined,
              selectionType: checkedRadioButton,
            });
            if (handleClose) {
              handleClose();
            }
          }}
        >
          {tResourcePlanner('Ok').toUpperCase()}
        </Button>
      </DialogActions>
    </>
  );
};
