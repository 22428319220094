import { IMenus, IMenusProperties } from 'src/apis/types/menusAPI';

export const getStringAttribute = (dataProperties: any, key: string): string | undefined =>
  dataProperties[key] || undefined;

export const toCamel = (inputJson: Record<string, any>): any => {
  const newObject: Record<string, any> = {};
  if (inputJson instanceof Array) {
    return inputJson?.map((value) => {
      if (typeof value === 'object') {
        return toCamel(value);
      }
      return value;
    });
  }

  Object.keys(inputJson).forEach((key) => {
    const newKey = (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();
    const jsonValue = inputJson[key];
    if (jsonValue instanceof Array || (jsonValue !== null && jsonValue.constructor === Object)) {
      newObject[newKey] = toCamel(jsonValue);
    } else {
      newObject[newKey] = jsonValue;
    }
  });

  return newObject;
};

export const toPascal = (inputJson: Record<string, any>): any => {
  const newObject: Record<string, any> = {};
  if (inputJson instanceof Array) {
    return inputJson?.map((value) => {
      if (typeof value === 'object') {
        return toPascal(value);
      }
      return value;
    });
  }

  Object.keys(inputJson).forEach((key) => {
    const newKey = (key.charAt(0).toUpperCase() + key.slice(1) || key).toString();
    const jsonValue = inputJson[key];
    if (jsonValue instanceof Array || (jsonValue !== null && jsonValue.constructor === Object)) {
      newObject[newKey] = toPascal(jsonValue);
    } else {
      newObject[newKey] = jsonValue;
    }
  });

  return newObject;
};

export const parseMenuPropertiesMapping = (menuApi: IMenus | undefined): IMenusProperties[] =>
  menuApi?.entities?.map(({ properties: { ...allProps } }) => allProps) ?? [];

export const safeParseJson = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (ex) {
    return null;
  }
};
