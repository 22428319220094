import { useCallback, useEffect } from 'react';
import {
  unstable_Blocker as Blocker,
  unstable_BlockerFunction as BlockerFunction,
  useActionData,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import { Button } from 'src/components/mui-components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'src/components/mui-components/Dialog';

export interface IConfirmNavigation {
  blocker: Blocker;
  title: string;
  description: string;
  onAction: () => void;
  okText: string;
  cancelText: string;
}

export const ConfirmNavigation = ({
  blocker,
  title,
  description,
  onAction,
  okText,
  cancelText,
}: IConfirmNavigation) => (
  <Dialog
    open={blocker.state === 'blocked'}
    aria-labelledby="navigation-blocker"
    aria-describedby="navigation-blocker-desc"
  >
    <DialogTitle id="navigation-blocker">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="navigation-blocker-desc">{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={() => {
          blocker.proceed?.();
        }}
      >
        {cancelText}
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          blocker.reset?.();
          onAction();
        }}
        autoFocus
      >
        {okText}
      </Button>
    </DialogActions>
  </Dialog>
);

interface INavigationBlocker {
  isBlock: boolean;
  setIsBlock: (isBlock: boolean) => void;
  title: string;
  description: string;
  onAction: () => void;
  okText: string;
  cancelText: string;
}

export const NavigationBlocker = ({
  isBlock,
  setIsBlock,
  title,
  description,
  onAction,
  okText = 'Yes',
  cancelText = 'No',
}: INavigationBlocker) => {
  const actionData = useActionData() as { ok: boolean } | undefined;

  // Allow the submission navigation to the same route to go through
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      isBlock && currentLocation.pathname !== nextLocation.pathname,
    [isBlock],
  );
  const blocker = useBlocker(shouldBlock);

  // Unblock after a user click ok
  useEffect(() => {
    if (actionData?.ok) {
      setIsBlock(false);
    }
  }, [actionData, setIsBlock]);

  return blocker ? (
    <ConfirmNavigation
      blocker={blocker}
      title={title}
      description={description}
      onAction={onAction}
      okText={okText}
      cancelText={cancelText}
    />
  ) : null;
};
