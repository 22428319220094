import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { MenuItem, Stack, FormControl, InputLabel } from 'src/components/mui-components';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IViewOptionsField, ViewOptionsChangeParameters } from '../../../../types';

interface ResourceViewOptionsProps {
  viewOptionsPeriodPicker?: ReactNode;
  viewOptionsFields?: Array<IViewOptionsField>;
  viewOptionsChange?: (item: ViewOptionsChangeParameters[]) => void;
}

export const ViewOptions = ({
  viewOptionsPeriodPicker,
  viewOptionsFields,
  viewOptionsChange,
}: ResourceViewOptionsProps) => {
  const { t } = useTranslation('viewOptions');
  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    viewOptionsChange?.([{ name, value }]);
  };

  return (
    <Stack
      direction="row"
      justifyContent={viewOptionsPeriodPicker ? 'space-between' : 'flex-end'}
      alignItems="bottom"
    >
      {viewOptionsPeriodPicker && viewOptionsPeriodPicker}

      {viewOptionsFields && viewOptionsFields.length > 0 && (
        <Stack direction="row">
          {viewOptionsFields?.map(({ name: fieldName, value: fieldValue, options }) => (
            <FormControl key={fieldName} size="small">
              <InputLabel id={`${fieldName}-label`}>
                {translationAnyText(t, `FieldOption${stringToPascal(fieldName)}Label`)}
              </InputLabel>
              <Select
                labelId={`${fieldName}-label`}
                name={fieldName}
                label={translationAnyText(t, `FieldOption${stringToPascal(fieldName)}Label`)}
                value={fieldValue}
                onChange={(e) => {
                  handleSelectChange(e);
                }}
                data-automation-id={`ViewOptions${stringToPascal(fieldName)}`}
              >
                {options.map(({ value }) => {
                  const label = translationAnyText(t, `FieldOption${stringToPascal(value)}`);

                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
