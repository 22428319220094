import {
  CloseButtonProps,
  ToastContainer as ToastifyToastContainer,
  TypeOptions,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton } from 'src/components/ui-components/IconButton';

// Exclude 'info' as we do not have info status
type ExcludeInfo<T> = T extends 'info' ? never : T;
type TypeOmitInfo = ExcludeInfo<TypeOptions>;

const ToastCloseButton = ({
  closeToast,
  type,
}: Partial<CloseButtonProps> & { type?: TypeOmitInfo }) => (
  <IconButton
    border
    round
    size="tiny"
    iconName="cross"
    onClick={closeToast}
    tooltipText="Close"
    style={{
      position: 'absolute',
      top: '50%',
      right: 16,
      transform: 'translateY(-50%)',
    }}
    tone={type}
  />
);

export const ToastContainer = () => (
  <ToastifyToastContainer
    position="bottom-center"
    autoClose={false}
    hideProgressBar
    newestOnTop
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton={<ToastCloseButton />}
    icon={false}
    style={{ zIndex: 20 }}
    bodyStyle={{ padding: 0 }}
    toastStyle={{ padding: 0 }}
  />
);
