import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef, Getter, RowData } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_RESOURCE_KEY,
  useGetGroupByResource,
} from 'src/apis/resourcePlannerAPI';
import { useGetPartialGroupByResource } from 'src/apis/resourcePlannerAPI/get/getGroupByResourceAPI';
import {
  GROUP_BY_RESOURCE_TOTAL_KEY,
  useGetGroupByResourceTotal,
} from 'src/apis/resourcePlannerAPI/get/getGroupByResourceTotalAPI';
import postResourcePlannerChange from 'src/apis/resourcePlannerAPI/post/postResourcePlannerChange';
import {
  IResourcePlannerColumn,
  IResourcePlannerItem,
  IResourcePlannerPeriodValueString,
  IResourcePlannerTotalResult,
} from 'src/apis/types/resourcePlannerAPI';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { Spinner, TCellText, THorizontalAlignment } from 'src/components/ui-components';
import { useFilterDispatch, useFilterStore } from 'src/stores/FilterStore';
import {
  getDateStringFromSiteLocale,
  getDateWithZeroOffsetFromDateString,
  getLanguageFromSiteLocale,
} from 'src/utils/date';
import { translationAnyText } from 'src/utils/translation';
import { RPShimmerRowCount } from '../../constants';
import camelToPascal from '../../helper/camelToPascal';
import { findRowById, getExpandedRowIds, setExpandedRowIds } from '../../helper/expandedRow';
import generatePeriodLabel from '../../helper/generatePeriodLabel';
import { getShimmerRows } from '../../helper/generateShimmerRows';
import { getFixedColumnTitleIdentifier } from '../../helper/getFixedColumnTooltip';
import {
  RPEmployeeViewInitialExpandedRowsStateKey,
  RPProjectViewInitialExpandedRowsStateKey,
  RPSelectedFilterListStateKey,
  RPinvalidateOldQueryOnViewOptionChange,
} from '../../localStorageKeys';
import { RPRow, RTColumn, RTRow } from '../../types/resourcePlanner';
import ExcessiveOrNoData from '../ExcessiveOrNoData';
import { GeneralColumnRenderer } from '../GeneralColumnRenderer';
import TotalColumnRenderer from '../TotalColumnRenderer';
import ResourceTableGroupedByEmployeeTable from './ResourceTableGroupedByEmployeeTable';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    format?: string;
    editable?: boolean;
    alignment?: THorizontalAlignment;
    type?: TCellText;
    startsAt?: string;
    endsAt?: string;
    periodIndex?: number;
    isPeriod?: boolean;
    dividerBorderLeft?: boolean;
    title?: string;
  }
}

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    updateData?: (row: RTRow, column: RTColumn, value: string) => void;
  }
}

const shimmerRowId = '-9999999';
const fixedColumns: IResourcePlannerColumn[] = [
  {
    identifier: 'status',
  },
  {
    identifier: 'startsAt',
    format: 'date',
    alignment: 'center',
  },
  {
    identifier: 'endsAt',
    format: 'date',
    alignment: 'center',
  },
  {
    identifier: 'budget',
    alignment: 'right',
    format: 'number',
  },
  {
    identifier: 'totalBooked',
    alignment: 'right',
    format: 'number',
  },
  {
    identifier: 'totalActualWorkload',
    alignment: 'right',
    format: 'number',
  },
  {
    identifier: 'notPlanned',
    alignment: 'right',
    format: 'number',
  },
];

interface IResourceTableGroupedByEmployee {
  rpLoading: boolean;
  selectedViewOptions: {
    [key: string]: string;
  };
}

const getTranslatedText = (
  t: TFunction<'resourcePlanner', undefined>,
  unitType: string,
  identifier: string,
) => {
  if (identifier === 'budget') {
    return translationAnyText(
      t,
      `ColumnLabel${camelToPascal(identifier)}${
        unitType === 'days' ? 'Days' : 'Hours'
      }EmployeeView`,
    );
  }
  if (
    identifier === 'totalBooked' ||
    identifier === 'totalActualWorkload' ||
    identifier === 'notPlanned'
  ) {
    return translationAnyText(
      t,
      `ColumnLabel${camelToPascal(identifier)}${unitType === 'days' ? 'Days' : 'Hours'}`,
    );
  }
  return translationAnyText(t, `ColumnLabel${camelToPascal(identifier)}`);
};

export const ResourceTableGroupedByEmployee = ({
  rpLoading,
  selectedViewOptions,
}: IResourceTableGroupedByEmployee) => {
  const { t } = useTranslation('resourcePlanner');
  const qc = useQueryClient();
  const { filterQueryObj, isRequiredQuery } = useFilterStore();
  const filterDispatch = useFilterDispatch();

  // Wrap this in useEffect to fix warning described in this stack overflow post:
  // https://stackoverflow.com/questions/62336340/cannot-update-a-component-while-rendering-a-different-component-warning
  useEffect(() => {
    if (isRequiredQuery) {
      if (filterDispatch) filterDispatch({ type: 'DEACTIVATE_RENDER' });
    }
  }, [filterDispatch, isRequiredQuery]);

  // get filter from localStorage and compare with newest filter. If change reset expanded ids
  if (JSON.stringify(filterQueryObj) !== localStorage.getItem(RPSelectedFilterListStateKey)) {
    localStorage.removeItem(RPEmployeeViewInitialExpandedRowsStateKey);
    localStorage.removeItem(RPProjectViewInitialExpandedRowsStateKey);
  }
  localStorage.setItem(RPSelectedFilterListStateKey, JSON.stringify(filterQueryObj));
  // TODO: Delay loading to avoid duplicate partial-group-by-employee-first-load?
  const { isLoading, isEmpty, periods, children, responseType, resourceIds } =
    useGetGroupByResource({ selectedFilterList: filterQueryObj }, selectedViewOptions, rpLoading);
  const { data: totalRow } = useGetGroupByResourceTotal(resourceIds, selectedViewOptions);
  const getRowsByParentId = useGetPartialGroupByResource(
    { selectedFilterList: filterQueryObj },
    selectedViewOptions,
  );
  const { mutate: postChange } = postResourcePlannerChange();
  const unitType = selectedViewOptions['unit-types'];
  const { currentLanguage } = useGetCurrentLanguage();

  const siteLocale = useGetLocale();
  const siteLanguage = getLanguageFromSiteLocale(siteLocale);

  const columns = useMemo<ColumnDef<RPRow>[]>(
    () => [
      {
        id: 'expandCollapseAllRows',
        accessorKey: 'name',
        header: () => t('ColumnLabelName'),
        cell: ({ getValue }) => getValue() as string,
      },
      ...fixedColumns.map((fixedColumn) => ({
        id: fixedColumn.identifier,
        accessorKey: fixedColumn.identifier,
        meta: {
          editable: false,
          format: fixedColumn?.format,
          alignment: fixedColumn?.alignment as THorizontalAlignment,
          dividerBorderLeft: fixedColumn.identifier === 'budget',
          title: translationAnyText(t, getFixedColumnTitleIdentifier(fixedColumn.identifier)),
        },
        header: () => getTranslatedText(t, unitType, fixedColumn.identifier),
        cell: ({
          row,
          column,
          getValue,
        }: {
          row: RTRow;
          column: RTColumn;
          getValue: Getter<string>;
        }) =>
          GeneralColumnRenderer({
            row,
            id: column.columnDef.id,
            format: column.columnDef.meta?.format,
            value: getValue(),
          }),
      })),
      ...periods.map((periodColumn, index) => ({
        id: `periodCol_${periodColumn.identifier}`,
        accessorFn: (row: RPRow) => row.values[periodColumn.identifier],
        meta: {
          editable: true,
          alignment: 'right' as THorizontalAlignment,
          startsAt: periodColumn.startsAt,
          endsAt: periodColumn.endsAt,
          dividerBorderLeft: index === 0,
          title:
            selectedViewOptions['period-types'] !== 'day'
              ? `${getDateStringFromSiteLocale(
                  getDateWithZeroOffsetFromDateString(periodColumn.startsAt),
                  siteLanguage,
                )} - ${getDateStringFromSiteLocale(
                  getDateWithZeroOffsetFromDateString(periodColumn.endsAt),
                  siteLanguage,
                )}`
              : '',
        },
        header: () =>
          generatePeriodLabel(
            periodColumn.identifier,
            selectedViewOptions['period-types'],
            currentLanguage,
            t,
          ),
        cell: ({ getValue }: { getValue: Getter<IResourcePlannerPeriodValueString> }) =>
          getValue().displayValue,
      })),
      {
        id: 'total',
        accessorKey: 'total',
        meta: {
          editable: true,
          alignment: 'right' as THorizontalAlignment,
          type: 'total',
        },
        header: () => t('ColumnLabelTotal'),
        cell: ({ row }) =>
          TotalColumnRenderer({
            displayValue: row.original.total?.displayValue,
            unitType,
          }),
      },
    ],
    [selectedViewOptions, periods, t, currentLanguage, unitType, siteLanguage],
  );

  const onCellValueChange = useCallback(
    (row: RTRow, column: RTColumn, value: string) => {
      const ids = row.id.split('.').map((i) => parseInt(i, 10));
      const cachedData:
        | { model: { properties: { children: IResourcePlannerItem[] } } }
        | undefined = qc.getQueryData([
        GROUP_BY_RESOURCE_KEY,
        selectedViewOptions || {},
        filterQueryObj || {},
      ]);

      if (cachedData) {
        const findRow = (allRows: RPRow[], indices: number[]): RPRow => {
          const index = indices.shift();

          if (index === undefined) {
            return allRows[0];
          }

          const currentRow = allRows[index];

          if (currentRow === undefined) {
            return allRows[0];
          }

          if (indices.length && currentRow.children && currentRow.children.length) {
            return findRow(currentRow.children, indices);
          }

          return currentRow;
        };

        const updatedRow = findRow(cachedData.model.properties.children, ids);

        if (!column.id) {
          throw new Error('Could not find updated column');
        }

        const periodColumnIdSubstring = column.id.substring(
          column.id.indexOf('_') + 1,
          column.id.length,
        );

        const updatedCell = updatedRow.values[periodColumnIdSubstring];

        updatedRow.values[periodColumnIdSubstring] = {
          ...updatedCell,
          displayValue: value,
        };

        qc.setQueryData(
          [GROUP_BY_RESOURCE_KEY, selectedViewOptions || {}, filterQueryObj || {}],
          cachedData,
        );
      }

      const { resourceId, workItemId } = row.original;
      const startsAt = column.columnDef.meta?.startsAt!;
      const endsAt = column.columnDef.meta?.endsAt!;

      postChange(
        {
          resourceId,
          workItemId,
          unitType,
          value,
          startsAt,
          endsAt,
        },
        {
          onSettled: () => {
            qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
            qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
            qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
          },
        },
      );
    },
    [filterQueryObj, postChange, qc, selectedViewOptions, unitType],
  );

  const toggleCurrentRowAndUpdateState = (storageKey: string, row: any) => {
    const expandedRowIds = getExpandedRowIds(storageKey);
    const toggledRow = expandedRowIds.find((item) => item.tableRowId === row.id);
    if (toggledRow) {
      toggledRow.isExpanded = !row.getIsExpanded();
    } else {
      expandedRowIds.push({
        tableRowId: row.id,
        originalId: row.original.id,
        isExpanded: !row.getIsExpanded(),
      });
    }
    setExpandedRowIds(
      storageKey,
      expandedRowIds.filter((item) => item.isExpanded),
    );
    localStorage.setItem(RPinvalidateOldQueryOnViewOptionChange, 'true');
    row.toggleExpanded();
  };

  const handleOnToggleRowCallback = useCallback(
    async (row: any) => {
      const cachedDataKey: any[] = [
        GROUP_BY_RESOURCE_KEY,
        selectedViewOptions || {},
        filterQueryObj || {},
      ];

      const storageKey = RPEmployeeViewInitialExpandedRowsStateKey;
      const initialChildren = row.original?.children ? row.original?.children : undefined;
      const isShimmerChildren =
        initialChildren !== undefined &&
        initialChildren.length > 0 &&
        initialChildren[0].id === shimmerRowId;
      const isToExpandCurrentRow = !row.getIsExpanded();
      toggleCurrentRowAndUpdateState(storageKey, row);

      if (isToExpandCurrentRow && (initialChildren === undefined || isShimmerChildren)) {
        const cachedData =
          qc.getQueryData(cachedDataKey) !== undefined &&
          JSON.stringify(qc.getQueryData(cachedDataKey))
            ? JSON.parse(JSON.stringify(qc.getQueryData(cachedDataKey)))
            : undefined;
        const modifiedRow = findRowById(cachedData?.model.properties.children, row.original.id);
        const modifiedRowChildren = modifiedRow?.children ? modifiedRow.children : undefined;
        let rawChildRowsFromServer;
        let didDataFetch = false;

        const isShimmerRow =
          modifiedRowChildren !== undefined &&
          modifiedRowChildren.length > 0 &&
          modifiedRowChildren[0].id === shimmerRowId;

        if (modifiedRowChildren === undefined || isShimmerRow) {
          try {
            getRowsByParentId.reset();
            if (modifiedRow) {
              modifiedRow.children = getShimmerRows(shimmerRowId, RPShimmerRowCount); // add shimmer rows
              qc.setQueryData(cachedDataKey, cachedData);
            }
            rawChildRowsFromServer = await getRowsByParentId.mutateAsync(row.original.id);
            didDataFetch = true;
          } catch (e) {
            // TODO: Should we notify the user that an error has occurred?
            if (modifiedRow) modifiedRow.children = initialChildren; // making sure to remove shimmer rows
            qc.setQueryData(cachedDataKey, cachedData);
            return;
          }
        }

        let childRows;
        if (didDataFetch) {
          childRows = rawChildRowsFromServer?.model?.properties?.children
            ? rawChildRowsFromServer?.model?.properties?.children
            : undefined;

          // fix for single row expand on lowest level
          if (rawChildRowsFromServer?.model?.properties?.children[0].id === modifiedRow?.id) {
            childRows = rawChildRowsFromServer?.model?.properties?.children[0].children
              ? rawChildRowsFromServer?.model?.properties?.children[0].children
              : undefined;
          }
        } else {
          childRows = modifiedRowChildren;
        }

        const cachedDataToBeUpdated =
          qc.getQueryData(cachedDataKey) !== undefined &&
          JSON.stringify(qc.getQueryData(cachedDataKey)) !== undefined
            ? JSON.parse(JSON.stringify(qc.getQueryData(cachedDataKey)))
            : undefined;
        const modifiedRowToBeUpdated = findRowById(
          cachedDataToBeUpdated?.model.properties.children,
          row.original.id,
        );

        if (modifiedRowToBeUpdated && childRows && childRows.length) {
          modifiedRowToBeUpdated.children = childRows;
        } else if (modifiedRowToBeUpdated) {
          // this should not be te case, as the rows without children should have a "canExpand" property equal false
          modifiedRowToBeUpdated.children = modifiedRowChildren;
        }
        qc.setQueryData(cachedDataKey, cachedDataToBeUpdated);
      }
    },
    [filterQueryObj, getRowsByParentId, qc, selectedViewOptions],
  );

  const setExpandedRowsAndAddShimmer = (expandedIdsString: string, rows: any) => {
    if (rows) {
      const cachedDataKey = [
        GROUP_BY_RESOURCE_KEY,
        selectedViewOptions || {},
        filterQueryObj || {},
      ];

      const cachedData:
        | { model: { properties: { children: IResourcePlannerItem[] } } }
        | undefined = JSON.parse(JSON.stringify(qc.getQueryData(cachedDataKey)));

      const expandedIds = expandedIdsString.split(',');

      for (let i = 0; i < rows.length; i += 1) {
        if (expandedIds.findIndex((eId: string) => eId === rows[i].original.id)) {
          const modifiedRow = findRowById(
            cachedData?.model.properties.children,
            rows[i].original.id,
          );
          if (modifiedRow) {
            modifiedRow.children = getShimmerRows(shimmerRowId, RPShimmerRowCount); // add shimmer rows
          }
        }
      }
      qc.setQueryData(cachedDataKey, cachedData);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (responseType === 'ExcessiveData' || (isEmpty && responseType === 'NoData')) {
    return <ExcessiveOrNoData type={responseType} />;
  }

  const partialTotalRow: Partial<IResourcePlannerTotalResult> = {};

  return (
    <ResourceTableGroupedByEmployeeTable
      columns={columns}
      data={children}
      onCellValueChange={onCellValueChange}
      unitType={unitType}
      selectedViewOptions={selectedViewOptions}
      totalRow={totalRow ?? partialTotalRow}
      periods={periods}
      t={t}
      handleOnToggleRow={handleOnToggleRowCallback}
      setExpandedRows={setExpandedRowsAndAddShimmer}
    />
  );
};
