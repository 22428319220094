import {
  DesktopDatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export const DatePicker = ({ ...rest }: MuiDatePickerProps<Date>) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <MuiDatePicker {...rest} />
  </LocalizationProvider>
);
