import { useTranslation } from 'react-i18next';
import { useActive, useCommands } from '@remirror/react';
import { MenuItem, Select } from 'src/components/mui-components';
import { SelectChangeEvent } from '@mui/material/Select';

export const SelectHeading = () => {
  const commands = useCommands();
  const active = useActive();

  const { t } = useTranslation('richTextEditor');

  const selectOnChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    switch (value) {
      case 'quote':
        commands.toggleBlockquote();
        break;
      case 'paragraph':
        commands.toggleHeading();
        break;
      case 'h1':
        commands.toggleHeading({ level: 1 });
        break;
      case 'h2':
        commands.toggleHeading({ level: 2 });
        break;
      case 'h3':
        commands.toggleHeading({ level: 3 });
        break;
      default:
        break;
    }
    commands.focus();
  };

  const getValue = () => {
    if (active.heading({ level: 1 })) {
      return 'h1';
    }
    if (active.heading({ level: 2 })) {
      return 'h2';
    }
    if (active.heading({ level: 3 })) {
      return 'h3';
    }
    if (!(active.heading() || active.blockquote() || active.codeBlock())) {
      return 'paragraph';
    }
    if (active.blockquote()) {
      return 'quote';
    }

    return undefined;
  };

  return (
    <Select
      data-automation-id="slctHeading"
      inputProps={{
        'aria-label': t('FormatsSelectLabel'),
        'data-automation-id': 'slctHeadingInput',
      }}
      onChange={selectOnChange}
      disabled={active.codeBlock() || !commands.toggleHeading.enabled()}
      name="formats"
      value={getValue()}
      size="small"
    >
      <MenuItem value="h1">{t('FormatHeading1Label')}</MenuItem>
      <MenuItem value="h2">{t('FormatHeading2Label')}</MenuItem>
      <MenuItem value="h3">{t('FormatHeading3Label')}</MenuItem>
      <MenuItem value="paragraph">{t('ParagraphLabel')}</MenuItem>
      <MenuItem value="quote">{t('FormatQuoteLabel')}</MenuItem>
    </Select>
  );
};
