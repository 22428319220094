import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IFrontPageResult } from '../types';

const GET_FRONT_PAGE_FEATURE_URL_PATH = '/api/v2/general/features';
export const GET_FRONT_PAGE_FEATURE_MSW_STATUS_KEY = 'getFrontPageFeatureAPI';
export const GET_FRONT_PAGE_FEATURE_KEY = 'GET_FRONT_PAGE_FEATURE';

const getFrontPageFeature = (): Promise<IFrontPageResult> =>
  getFetch({
    path: GET_FRONT_PAGE_FEATURE_URL_PATH,
    key: GET_FRONT_PAGE_FEATURE_MSW_STATUS_KEY,
  });

export const useGetFrontPageFeature = () => {
  const { data, ...rest } = useQuery([GET_FRONT_PAGE_FEATURE_KEY], getFrontPageFeature);

  const featureData = data?.properties ? data.properties?.featureList : [];

  return {
    data: featureData,
    ...rest,
  };
};
